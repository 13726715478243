<template>
  <div class="page">
    <!-- 头部 -->
    <!-- <nav_top /> -->
    <div class="zhanwei">
      <!-- 占位 -->
    </div>
    <div class="connent_bg container">
      <div class="left_nav">
        <div class="head_portrait">
          <img :src="user.userlogo" alt="" />
        </div>
        <div class="name">{{ logined == true ? user.pname : "请登录" }}</div>
        <ul class="nav_ul">
          <!-- :to="{ path: item.path, query: { item: index } }" -->
          <router-link
            :to="{ path: item.path }"
            v-for="(item, index) in navList"
            :key="index"
          >
            <li
              :class="{ 'router-link-exact-active': nav_index == item.path }"
              @click="change_nav(index, item.path)"
            >
              <img :src="item.iconL" alt="" />
              {{ item.name }}
            </li>
          </router-link>
        </ul>
      </div>
      <!-- <div class="right_connent"> -->
      <transition name="el-fade-in-linear" mode="out-in">
        <router-view> </router-view>
      </transition>
      <!-- </div> -->
    </div>

    <!-- 底部 -->
    <!-- <footer_ /> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "my_center",
  data() {
    return {
      nav_path: "/mine/my_center",
      navList: [
        {
          id: 0,
          path: "/mine/my_center",
          iconL: require("@/assets/mine/icon_0.png"),
          name: "我的资料",
          is_on: true,
        },
        {
          id: 1,
          path: "/mine/my_course",
          iconL: require("@/assets/mine/course.png"),
          name: "我的课程",
          is_on: false,
        },
        {
          id: 2,
          path: "/mine/my_order",
          iconL: require("@/assets/mine/icon_1.png"),
          name: "我的订单",
          is_on: false,
        },
        {
          id: 3,
          path: "/mine/change_code",
          iconL: require("@/assets/mine/icon_2.png"),
          name: "兑换码",
          is_on: false,
        },

        // {
        //   id: 3,
        //   path: "/mine/my_center",
        //   iconL: require("@/assets/mine/icon_3.png"),
        //   name: "我的拼团",
        //   is_on: false,
        // },
        {
          id: 5,
          path: "/mine/my_coupon",
          iconL: require("@/assets/mine/icon_4.png"),
          name: "卡券红包",
          is_on: false,
        },
        {
          id: 6,
          path: "/mine/my_address",
          iconL: require("@/assets/mine/icon_6.png"),
          name: "我的地址",
          is_on: false,
        },
        {
          id: 7,
          path: "/mine/feedback",
          iconL: require("@/assets/mine/icon_7.png"),
          name: "意见反馈",
          is_on: false,
        },
        {
          id: 8,
          path: "/mine/help_center",
          iconL: require("@/assets/mine/icon_8.png"),
          name: "常见问题",
          is_on: false,
        },
        {
          id: 9,
          path: "/mine/account_settings",
          iconL: require("@/assets/mine/icon_9.png"),
          name: "账户设置",
          is_on: false,
        },
        {
          id: 10,
          path: "/mine/mock_record",
          iconL: require("@/assets/mine/icon_10.png"),
          name: "我的模考记录",
          is_on: false,
        },
      ],
      nav_index: "/mine/my_center",
      user: {},
      logined: false,
    };
  },

  methods: {
    ...mapActions(["setUserAndState"]),
    change_nav(index, path) {
      this.nav_index = path;
      if (path == this.$route.path) {
      } else {
        this.$router.push({ path: `${path}` });
      }
    },
    async getData() {
      let islogin = await this.islogin();

      if (islogin) {
        this.logined = true;
        this.user = this.$store.state.user;
      } else {
        this.logined = false;
        this.user = {};
      }
    },
    islogin() {
      this.setUserAndState();
      const { logined } = this.$store.state;
      if (logined) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    let path = this.$route.path;
    if (path) {
      this.nav_index = path;
    }
  },
  watch: {
    $route(to, from) {
      let path = this.$route.path;
      if (path == this.nav_index) {
      } else {
        this.nav_index = path;
      }
    },
  },
  components: {},
  created() {
    this.getData();
  },
};
</script>
<style scoped></style>

<style lang="less" scoped>
.page {
  background-color: #f5f6f8;
  min-height: calc(100vh - 451px);
  padding-bottom: 100px;
}
.zhanwei {
  height: 22px;
}
.connent_bg {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .left_nav {
    width: 240px;
    // height: 791px;
    background: #ffffff;
    border-radius: 16px;
    padding: 26px 20px;
    .head_portrait {
      img {
        width: 100px;
        height: 100px;
        background: #479dff;
        border-radius: 50%;
      }
    }
    .name {
      font-size: 20px;

      font-weight: 600;
      color: #1f1f1f;
      line-height: 28px;
      // width: 80%;
      margin: 20px auto;
      white-space: nowrop;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .nav_ul {
      　li.router-link-exact-active {
        width: 240px;
        height: 50px;
        background: #eff3fd;
        border-radius: 10px;
        color: #479dff !important;
      }
      li.on {
        width: 240px;
        height: 50px;
        background: #eff3fd;
        border-radius: 10px;
        color: #479dff !important;
      }
      li:hover {
        width: 240px;
        height: 50px;
        background: #eff3fd;
        border-radius: 10px;
        color: #479dff !important;
      }
      li {
        height: 50px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        font-size: 16px;

        font-weight: 600;
        color: #333333;
        cursor: pointer;
        img {
          margin-left: 34px;
          width: 20px;
          height: 20px;
          margin-right: 13px;
        }
      }
    }
  }
  // .right_connent {
  //   padding: 40px 40px;
  //   background: #ffffff;
  //   border-radius: 16px;
  //   width: 820px;
  //   min-height: 674px;
  //   .nav_name {
  //     font-size: 20px;
  //     font-weight: bold;
  //     color: #1f1f1f;
  //     line-height: 28px;
  //     padding-bottom: 30px;
  //     border-bottom: 1px solid #e9e9e9;
  //   }
  // }
}
</style>
